/*!
 * Font Awesome Pro 5.10.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import "fontawesome/variables";
@import "fontawesome/mixins";
@import "fontawesome/core";
// @import 'fontawesome/larger';
@import "fontawesome/fixed-width";
// @import 'fontawesome/list';
// @import 'fontawesome/bordered-pulled';
// @import 'fontawesome/animated';
// @import 'fontawesome/rotated-flipped';
// @import 'fontawesome/stacked';
@import "fontawesome/icons";
// @import 'fontawesome/screen-reader';
