.orm__edit_icon {
  position: absolute;
  top: 26px;
  right: -30px;
  @media screen and (max-width: $md-viewport) {
    right: 0px;
  }
  cursor: pointer;
}
.orm__edit_input {
  cursor: pointer;
  height: 36px;

  @media screen and (max-width: $md-viewport) {
    width: 90%;
  }
}

.orm-border-left-mydata {
  &:before {
    content: "";
    border-left: 2px solid #d3d6d8;
    min-height: 250px;
    position: absolute;
    left: rem-calc(-56px);
    height: 100%;
    top: 0;
  }
  @media screen and (max-width: $lg-viewport) {
    display: none;
  }
}
.orm-customer-initals {
  border: 1px solid red;
  display: inline-block;
}
.orm-customer-name {
  display: inline-block;
}
