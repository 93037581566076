.orm-header {
  min-height: 100px;
  // overflow-x: auto;
  // display: block;
  // text-align: right;
}
.orm-headerNavigation {
  a {
    display: block;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      text-decoration: underline;
    }
  }

  @media (max-width: $md-viewport) {
    display: none;
    &-active {
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      position: absolute;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      padding: 0 1.25rem 1.25rem 1.25rem;
      border-radius: $border-radius-big;
      top: 84px;
      left: 16px;
      width: calc(100% - 32px);
      background-color: $white;
      z-index: 65;
      padding-top: 6px;
    }
    li {
      margin: 0;
      padding: rem-calc(12px) 0 0 0;
      list-style: none;
    }
    &:after {
      content: "";
      position: fixed;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100vh;
      width: 100%;
      left: 0;
      top: 100px;
      z-index: 60;
    }
  }

  @media (min-width: $md-viewport) {
    font-size: rem-calc(14px);
    // font-weight: 600;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    display: inline-block;
    justify-content: flex-end;
    align-items: center;
    margin-right: 16px;
    li {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0 0 0 rem-calc(24px);

      &:first-child {
        padding-left: 0;
      }
    }
  }
  .orm-filter__dropdown {
    right: rem-calc(16px);
  }
  @media (min-width: $lg-viewport) {
    //margin-bottom: rem-calc(24px);
    position: relative;
    overflow: visible;
    .orm-filter__dropdown {
      right: rem-calc(0);
    }
  }
}
.orm-burger {
  font-size: 24px;
  height: 36px;
  width: 36px;
  padding: 0;
  display: inline-block;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
  i {
    position: relative;
    top: 0px;
  }
  @media (min-width: $md-viewport) {
    display: none;
  }
  &:hover,
  &-active {
    box-shadow: 0px 0px 4px #999;
    border-radius: $border-radius-buttons;
  }
}

/* Language Manu */

.orm-langNavigation {
  position: relative;
  margin-right: 16px;
  margin-left: 16px;
  color: $orm_txt_dark_color;
}

.orm-langNavigation__select {
  margin: 14px 0;
  text-transform: uppercase;
  padding-right: 16px;
  cursor: pointer;
}

.orm-langNavigation__menu {
  /* display: none; */
  position: absolute;
  top: 30px;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 16px 16px 0;
  background-color: $white;
  // display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 50;
  display: none;
  &-active {
    display: flex !important;
  }
}
.orm-langNavigation__flag {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;

  & img {
    border-radius: 100%;
  }
}

li.orm-langNavigation__item {
  margin: 0;
  padding: 0 0 16px 0;
  width: 100%;
  display: flex;
}
.orm-headerNavigation li.orm-langNavigation__item {
  margin: 0;
  padding: 0 0 16px 0;
  width: 100%;
  display: flex;
}
.orm-langNavigation__item_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.orm-headerNavigation a.orm-langNavigation__item_link {
  display: flex;
  align-items: center;
}
@media (min-width: $md-viewport) {
  .orm-headerNavigation li.show-mobile-only {
    display: none !important;
  }
}

.orm-langNavigation_mobile {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 16px;

  & .orm-langNavigation__item_link {
    margin-right: 16px;
  }
}
