$offers_bp: 712px;
$offers_bp2: 790px;
$offers_bp3: 946px;

.orm-offerSlider {
  position: relative;
  width: 100%;
  // overflow: hidden;
  &:hover {
    .orm-offerSlider__controll__dots {
      visibility: visible;
      opacity: 1;
    }
  }

  &__image__container {
    padding-left: rem-calc(50px);
    padding-right: rem-calc(50px);
    // @media only screen and (min-width: $sm-viewport) {
    //   padding-left: rem-calc(80px);
    //   padding-right: rem-calc(80px);
    // }
    // @media only screen and (min-width: $lg-viewport) {
    //   padding-left: rem-calc(100px);
    //   padding-right: rem-calc(100px);
    // }
  }
  &__inner {
    transition: all 2s ease;
    > .orm-offerSlider__slideWrapper--offers {
      position: absolute;
      width: 100%;
      // height: 100%;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  &__slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &--hide {
      opacity: 0;
      transition: opacity 1s ease;
      // @media only screen and (max-width: $offers_bp) {
      //   position: absolute;
      // }
      // &.show {
      //   opacity: 1;
      //   @media only screen and (max-width: $offers_bp) {
      //     position: relative;
      //   }
      // }
    }

    &--offers {
      position: relative;
      background-size: cover;
      background-position: center top;
      background-color: $white;
      @media only screen and (max-width: $offers_bp) {
        background-size: contain;
      }
    }
  }
  &__controll {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    * {
      pointer-events: all;
    }
  }
  &--carcategory {
    .orm-btn {
      position: relative;
      z-index: 5;
    }
    .orm-cattitle,
    img {
      pointer-events: none;
      user-select: none;
    }
    // .orm-offerSlider__controll {
    //   position: unset;
    // }
    .orm-offerSlider__controll_arrows {
      // @media (max-width: $sm-viewport) {
      //   opacity: 1 !important;
      //   .orm-offerSlider__controll:hover & {
      //     opacity: 1 !important;
      //   }
      // }
    }

    .orm-offerSlider__controll_arrows {
      font-size: 40px;
      text-shadow: unset;
      pointer-events: visible;
    }
    .orm-offerSlider__inner {
      transition: all 2s ease;
      align-items: flex-end;
      flex-wrap: nowrap;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .orm-offerSlider__slide {
      &--hide {
        opacity: 0;
        width: 0%;
        height: 0%;
        transition: opacity 1s ease;
        animation-name: fade; // Name der Animation
        animation-duration: 1s; // Dauer der Animation
        animation-fill-mode: forwards;
        display: none;
        // @media only screen and (min-width: $md-viewport) {
        //   &:nth-of-type(2n) {
        //     animation-delay: 200ms;
        //   }
        // }
        // @media only screen and (min-width: $lg-viewport) {
        //   &:nth-of-type(3n-1) {
        //     animation-delay: 200ms;
        //   }
        //   &:nth-of-type(3n) {
        //     animation-delay: 400ms;
        //   }
        // }

        &.show {
          transition: opacity 1s ease;
          width: 100%;
          height: auto;
          display: block;

          // @media only screen and (max-width: $offers_bp) {
          //   position: relative;
          // }
        }
      }
    }
  }
  // &--offers {
  //   // @media only screen and (max-width: $offers_bp) {
  //   //   padding-top: 0 !important;
  //   // }
  //   // .orm-offerSlider__inner > div {
  //   //   @media only screen and (max-width: $offers_bp) {
  //   //     // position: relative;
  //   //     transition: opacity 2s ease;
  //   //     &.fade-leave-active {
  //   //       position: absolute;
  //   //     }
  //   //   }
  //   // }
  // }

  &__imagecontainer {
    height: 200px;

    img {
      width: auto;
      height: auto;
      max-height: 100%;
    }
  }
}

.orm-offerSlider__slideWrapper--offers {
  opacity: 1;
  z-index: 1;
  position: relative;
  overflow: hidden;
  // overflow: hidden;
  // background-color: #707070;
  // background-color: $white;
  transform: translateX(0%);
  // clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
  transition: transform 1s ease;

  .orm-offerSlider__content {
    opacity: 0;
    transition: height 1s ease;
  }
  @media only screen and (max-width: $offers_bp) {
    opacity: 1;
  }
  // &:nth-child(3) {
  //   // background-color: #df4;
  // }
  &.prev {
    transform: translateX(0%);
    z-index: 2;
    .orm-offerSlider__content {
      opacity: 1;
    }
  }

  .orm-offerSlider__slide {
    opacity: 1 !important;
  }

  &.show {
    animation-name: skewSlide; // Name der Animation
    animation-duration: 1.5s; // Dauer der Animation
    animation-fill-mode: forwards;
    z-index: 3;
    // overflow: hidden;

    .orm-offerSlider__slide_image--offers {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      background-size: cover;
    }

    .orm-offerSlider__content {
      opacity: 1;
      @media only screen and (min-width: $offers_bp) {
        opacity: 0;
        animation-name: fade; // Name der Animation
        animation-duration: 1.5s; // Dauer der Animation
        animation-fill-mode: forwards;
        animation-delay: 1.2s;
        margin-bottom: 0;
      }
    }
  }
}

.orm-offerSlider--offers {
  overflow: hidden;
  transition: height 1s ease;
  // &.animate {
  //   overflow: visible;
  // }
  &.init {
    overflow: hidden;
    opacity: 0;
    animation-name: init; // Name der Animation
    animation-duration: 1.5s; // Dauer der Animation
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
  }
}
.animate.orm-offerSlider--offers .orm-offerSlider__inner {
  // .next {
  //   transform: translateX(100%);
  //   z-index: 1;
  // }
  .preHover {
    left: 100%;
  }
  // .hover {
  //   // z-index: 4;
  //   // transition: transform 1s ease;
  //   // clip-path: polygon(6% 0%, 100% 0, 100% 100%, 0% 100%);
  //   // transform: translateX(-70px);
  //   // animation-name: fade; // Name der Animation
  //   // animation-duration: 1.5s; // Dauer der Animation
  //   // animation-fill-mode: forwards;
  // }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes init {
  from {
    opacity: 0;
    overflow: hidden;
  }
  to {
    opacity: 1;
    overflow: visible;
  }
}
@keyframes conterSkew {
  from {
    transform: skew(20deg);
  }
  to {
    transform: skew(0deg);
  }
}
@keyframes skewSlide {
  0% {
    overflow: hidden;
    opacity: 1;
    transform: translateX(calc(100% - 70px));
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  }
  100% {
    overflow: hidden;
    opacity: 1;
    transform: translateX(0);
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
  }
}

.orm-offerSlider__content {
  // border-radius: $border-radius-big;
  background-color: $orm_offers_background;
  color: $orm_offers_text;
  padding: 0 rem-calc(16px);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 430px;
  // bottom: 10vh;
  // min-width: 40vw;
  // max-width: 40vw;
  z-index: 10;
  user-select: none;

  // @media only screen and (max-width: 1500px) {
  //   max-width: 437px;
  //   min-width: 437px;
  // }

  @media only screen and (max-width: $offers_bp3) {
    max-width: 320px;
    opacity: 1;
  }
  @media only screen and (max-width: $offers_bp) {
    max-width: none;
    width: 100%;
    position: relative;
    bottom: 0;
    // margin: rem-calc(32px) 0;
    background-color: rgb(46, 50, 54);
    opacity: 1;
  }

  &_headline {
    font-size: rem-calc(32px);
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
    padding-top: rem-calc(40px);
    font-weight: 600;
    line-height: 1.125;
    text-transform: uppercase;
    @media only screen and (max-width: $offers_bp3) {
      font-size: rem-calc(24px);
    }
    @media only screen and (max-width: $offers_bp) {
      padding-top: rem-calc(20px);
    }
    // @media (max-width: $sm-viewport) {
    //   font-size: rem-calc(32px);
    // }
  }
  &_subheadline {
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
    font-size: rem-calc(24px);
    padding-bottom: rem-calc(8px);
    display: block;
    @media only screen and (max-width: $offers_bp3) {
      font-size: 1.2rem;
    }
  }
  &_copy {
    font-size: rem-calc(16px);
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
    padding-bottom: rem-calc(16px);
    @media only screen and (max-width: $lg-viewport) {
      display: none;
    }
    @media only screen and (max-width: $offers_bp) {
      display: block;
    }
  }
  &_price {
    font-size: rem-calc(42px);
    font-weight: 600;

    @media only screen and (max-width: $offers_bp3) {
      font-size: rem-calc(36px);
    }
  }
  &_pricetext {
    display: block;
    padding-top: rem-calc(24px);
    @media only screen and (max-width: $lg-viewport) {
      padding-top: rem-calc(60px);
    }
    @media only screen and (max-width: $offers_bp2) {
      padding-top: rem-calc(30px);
    }
    @media only screen and (max-width: $offers_bp) {
      padding-top: 0;
    }
  }
  &_legaltext {
    font-size: rem-calc(12px);
    // padding-left: 2rem;
    // padding-right: 2rem;
    padding-bottom: 2rem;
    padding-top: 2.125rem;
    opacity: 0;
    // .next & {
    //   opacity: 0;
    // }
    .show & {
      opacity: 1;
    }
    .animate & {
      // opacity: 1;
      transition: all 1s ease;
    }
    .prev & {
      opacity: 0;
    }
    transition: all 1s ease;
    // bottom: -80px;
    // position: absolute;
  }
}

.orm-offerSlider__controll_arrows {
  position: absolute;
  cursor: pointer;
  font-size: 26px;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 0 3px rgba(0, 0, 0, 0.3);

  .orm-offerSlider__controll--fade & {
    opacity: 0;
    transition: opacity 500ms ease;
  }

  .orm-offerSlider__controll:hover & {
    opacity: 1;
  }

  @media (max-width: $sm-viewport) {
    opacity: 0;
    .orm-offerSlider__controll:hover & {
      opacity: 0;
    }
  }

  &:hover {
    text-shadow: 0 0 1px rgba(97, 97, 97, 0.7), 0 0 3px rgba(255, 255, 255, 0.3);
  }

  &--right {
    right: 1rem;
  }
  &--left {
    left: 1rem;
  }
}

.orm-offerSlider__controll_next__wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: height 1s ease;
}
.orm-offerSlider__controll_next {
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  font-size: rem-calc(14px);
  text-transform: uppercase;
  right: 2rem;
  bottom: 2rem;
  z-index: 10;
  color: #fff;
  transition: all 0.8s ease;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  pointer-events: all;
  i {
    font-size: rem-calc(42px);
    margin-left: rem-calc(8px);
  }
  &:hover {
    //color: #01843c;

    right: 24px;
  }
  @media only screen and (max-width: $offers_bp) {
    display: none;
  }
}
.orm-offerSlider--offers .orm-offerSlider__controll_arrows {
  text-shadow: 0 0 1px rgba($customer_color_cta2, 0.3),
    0 0 3px rgba($customer_color_cta2, 0.2);
  color: rgba($customer_color_cta2, 0.1);
  font-size: rem-calc(44px);

  &:hover {
    text-shadow: 0 0 1px rgba($customer_color_cta2, 0.7),
      0 0 3px rgba($customer_color_cta2, 0.3);
    color: rgba($customer_color_cta2, 1);
  }
}

.orm-nextArrow {
  @media only screen and (max-width: $offers_bp) {
    display: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  //   transform: translateY(30px);s
}

.backdrop {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  pointer-events: none;
  transition: height 1s ease;
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
    0px 0px 5.3px rgba(0, 0, 0, 0.028), 0px 0px 10px rgba(0, 0, 0, 0.035),
    0px 0px 17.9px rgba(0, 0, 0, 0.042), 0px 0px 33.4px rgba(0, 0, 0, 0.05),
    0px 0px 80px rgba(0, 0, 0, 0.07);
}

.orm-offerSlider__button {
  @media only screen and (max-width: $offers_bp) {
    margin-bottom: 50px;
  }
}

.orm-offerSlider__controll__dots {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem 1rem 1rem;
  position: absolute;
  bottom: -39px;
  width: 100%;

  z-index: 50;

  .orm-offerSlider__controll--fade & {
    opacity: 0;
    transition: opacity 500ms ease;
  }

  // @media only screen and (max-width: $sm-viewport) {
  //   opacity: 1 !important;
  // }

  .orm-offerSlider__controll:hover & {
    opacity: 1;
  }
}

.orm-offersofferSlider {
  .orm-offerSlider__controll__dots {
    width: 30vw;
    bottom: 1rem;
  }
}
.orm-offerSlider__controll__dot {
  margin: 0 0.5rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.orm-offerSlider__controll__dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid currentColor;
  background-color: transparent;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.orm-offerSlider__controll__dot .default span {
  display: none;
}
.orm-offerSlider__controll__dot--active {
  border-width: 6px;
  border-color: $customer_color_cta2;
}

.orm-offerSlider__controll__dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid #2e3236;
  background-color: #2e3236;
  -webkit-box-shadow: 0 0 1px rgba(#2e3236, 0.5), 0 0 3px rgba(#2e3236, 0.3);
  box-shadow: 0 0 1px rgba(#2e3236, 0.5), 0 0 3px rgba(#2e3236, 0.3);
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.orm-offerSlider__controll__dot--active {
  border-width: 6px;
  border-color: $customer_color_cta2;
}
