// input[type="checkbox"] {
//   display: none;
// }

// input[type="checkbox"] + label::before {
//   width: 15px;
//   height: 15px;
//   background-color: $white;
//   display: block;
//   content: "";
//   float: left;
//   margin-right: 5px;
//   border-radius: $border-radius-small;
//   border: 1px solid $grey;
// }

.orm-selectButtonStyle {
  width: 180px;
  height: 105px;
  border: 1px solid $customer_color_grey_md;
  border-radius: $border-radius-buttons;
  margin: rem-calc(16px) rem-calc(16px) rem-calc(16px) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: rem-calc(16px);
  padding-right: rem-calc(16px);
  cursor: pointer;
  &.active {
    border: 2px solid $customer_color_primary;
  }
}
