@use "sass:math";

/// Divide the given `$divident` by the given `$divisor`.
///
/// @param {Number} $divident - The divident.
/// @param {Number} $divisor - The divisor.
/// @param {Number} $precision - The precision decimals for the division.
///
/// @return {Number} The product of the division.
@function divide($dividend, $divisor, $precision: 12) {
  $sign: if(
    $dividend > 0 and $divisor > 0 or $dividend < 0 and $divisor < 0,
    1,
    -1
  );
  $dividend: math.abs($dividend);
  $divisor: math.abs($divisor);
  @if $dividend == 0 {
    @return 0;
  }
  @if $divisor == 0 {
    @error 'Cannot divide by 0';
  }
  $remainder: $dividend;
  $result: 0;
  $factor: 10;
  @while ($remainder > 0 and $precision >= 0) {
    $quotient: 0;
    @while ($remainder >= $divisor) {
      $remainder: $remainder - $divisor;
      $quotient: $quotient + 1;
    }
    $result: $result * 10 + $quotient;
    $factor: $factor * 0.1;
    $remainder: $remainder * 10;
    $precision: $precision - 1;
    @if ($precision < 0 and $remainder >= $divisor * 5) {
      $result: $result + 1;
    }
  }
  $result: $result * $factor * $sign;
  $dividend-unit: unit($dividend);
  $divisor-unit: unit($divisor);
  $unit-map: (
    "px": 1px,
    "rem": 1rem,
    "em": 1em,
    "%": 1%,
  );
  @if (
    $dividend-unit != $divisor-unit and map-has-key($unit-map, $dividend-unit)
  ) {
    $result: $result * map-get($unit-map, $dividend-unit);
  }

  @return $result;
}

$global-font-size: 100% !default;

/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.
@function strip-unit($num) {
  @return divide($num, $num * 0 + 1);
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$global-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $global-font-size;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == "%" {
    $base: ($base / 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == "rem" {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -zf-bp-to-em($value) {
  // Pixel and unitless values are converted to rems
  @if unit($value) == "px" or unitless($value) {
    $value: rem-calc($value, $base: 16px);
  }

  // Then the value is converted to ems
  @return strip-unit($value) * 1em;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != "number" {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == "em" {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != "rem" {
    $value: calc(strip-unit($value) / strip-unit($base) * 1rem);
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}

/// Converts a pixel, percentage, rem or em value to a unitless value based on a given font size. Ideal for working out unitless line heights.
///
/// @param {Number} $value - Value to convert to a unitless line height
/// @param {Number} $base - The font size to use to work out the line height - defaults to $global-font-size
///
/// @return {Number} - Unitless number
@function unitless-calc($value, $base: null) {
  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $global-font-size;
  }

  // First, lets convert our $base to pixels

  // If the base font size is a %, then multiply it by 16px
  @if unit($base) == "%" {
    $base: ($base / 100%) * 16px;
  }

  @if unit($base) == "rem" {
    $base: strip-unit($base) * 16px;
  }

  @if unit($base) == "em" {
    $base: strip-unit($base) * 16px;
  }

  // Now let's convert our value to pixels too
  @if unit($value) == "%" {
    $value: ($value / 100%) * $base;
  }

  @if unit($value) == "rem" {
    $value: strip-unit($value) * $base;
  }

  @if unit($value) == "em" {
    $value: strip-unit($value) * $base;
  }

  // 'px'
  @if unit($value) == "px" {
    @return strip-unit($value) / strip-unit($base);
  }

  // assume that line-heights greater than 10 are meant to be absolute in 'px'
  @if unitless($value) and ($value > 10) {
    @return $value / strip-unit($base);
  }

  @return $value;
}

///// box-shdow

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

/// MARGIN PADDING ORM FUNKTION

// t - for classes that set margin-top or padding-top
// b - for classes that set margin-bottom or padding-bottom
// l - for classes that set margin-left or padding-left
// r - for classes that set margin-right or padding-right
// x - for classes that set both *-left and *-right
// y - for classes that set both *-top and *-bottom
// $grid-breakpoints: xs, sm, md, lg !default;

@each $size in $padding-sizes {
  .orm {
    &-pt-#{$size} {
      padding-top: rem-calc($size) !important;
    }
    &-pb-#{$size} {
      padding-bottom: rem-calc($size) !important;
    }
    &-pl-#{$size} {
      padding-left: rem-calc($size) !important;
    }
    &-pr-#{$size} {
      padding-right: rem-calc($size) !important;
    }
    &-px-#{$size} {
      padding-left: rem-calc($size) !important;
      padding-right: rem-calc($size) !important;
    }
    &-py-#{$size} {
      padding-top: rem-calc($size) !important;
      padding-bottom: rem-calc($size) !important;
    }
  }
}

@each $breakpoint, $min in $grid-breakpoints {
  @media only screen and (min-width: $min) {
    @each $size in $padding-sizes {
      .orm {
        &-pt-#{$breakpoint}-#{$size} {
          padding-top: rem-calc($size) !important;
        }
        &-pb-#{$breakpoint}-#{$size} {
          padding-bottom: rem-calc($size) !important;
        }
        &-pl-#{$breakpoint}-#{$size} {
          padding-left: rem-calc($size) !important;
        }
        &-pr-#{$breakpoint}-#{$size} {
          padding-right: rem-calc($size) !important;
        }
        &-px-#{$breakpoint}-#{$size} {
          padding-left: rem-calc($size) !important;
          padding-right: rem-calc($size) !important;
        }
        &-py-#{$breakpoint}-#{$size} {
          padding-top: rem-calc($size) !important;
          padding-bottom: rem-calc($size) !important;
        }
      }
    }
  }
}

@each $size in $margin-sizes {
  .orm {
    &-mt-#{$size} {
      margin-top: rem-calc($size) !important;
    }
    &-mb-#{$size} {
      margin-bottom: rem-calc($size) !important;
    }
    &-ml-#{$size} {
      margin-left: rem-calc($size) !important;
    }
    &-mr-#{$size} {
      margin-right: rem-calc($size) !important;
    }
    &-mx-#{$size} {
      margin-left: rem-calc($size) !important;
      margin-right: rem-calc($size) !important;
    }
    &-my-#{$size} {
      margin-top: rem-calc($size) !important;
      margin-bottom: rem-calc($size) !important;
    }
  }
}

@each $breakpoint, $min in $grid-breakpoints {
  @media only screen and (min-width: $min) {
    @each $size in $padding-sizes {
      .orm {
        &-mt-#{$breakpoint}-#{$size} {
          margin-top: rem-calc($size) !important;
        }
        &-mb-#{$breakpoint}-#{$size} {
          margin-bottom: rem-calc($size) !important;
        }
        &-ml-#{$breakpoint}-#{$size} {
          margin-left: rem-calc($size) !important;
        }
        &-mr-#{$breakpoint}-#{$size} {
          margin-right: rem-calc($size) !important;
        }
        &-mx-#{$breakpoint}-#{$size} {
          margin-left: rem-calc($size) !important;
          margin-right: rem-calc($size) !important;
        }
        &-my-#{$breakpoint}-#{$size} {
          margin-top: rem-calc($size) !important;
          margin-bottom: rem-calc($size) !important;
        }
      }
    }
  }
}
