$icomoon-font-family: "cx9_fzga_ico" !default;

$orm_icon_pass: "\e914";
$orm_icon_gear: "\e903";
$orm_icon_luggage: "\e909";
$orm_icon_co2: "\e90d";
$orm_icon_weight: "\e90f";
$orm_icon_sitzer: "\e911";
$orm_icon_tuerer: "\e913";
$orm_icon_kombi: "\e916";
$orm_icon_addblue: "\e900";
$orm_icon_ahk: "\e901";
$orm_icon_allrad: "\e902";
$orm_icon_automatik: "\e904";
$orm_icon_bluetooth: "\e905";
$orm_icon_diesel: "\e906";
$orm_icon_ganzjahresreifen: "\e907";
$orm_icon_benzin: "\e908";
$orm_icon_klima: "\e90a";
$orm_icon_lkwkoffer: "\e90b";
$orm_icon_navi: "\e90c";
$orm_icon_rollstuhlrampe: "\e90e";
$orm_icon_sitzheizung: "\e910";
$orm_icon_winterreifen: "\e912";
