// html,
// select,
// button,
// a {
//   color: $orm_txt_dark_color;
//   font-family: "Open Sans";
// }
.orm-html {
  color: $orm_txt_dark_color;
  font-family: $font-family;
  font-size: rem-calc(16px);
  line-height: $global-line-height;
  // word-break: break-word;
  a {
    color: $orm_txt_dark_color;
    font-family: $font-family;
  }
}

.orm-headline {
  &__h1 {
    font-family: $font-family-headline;
    font-size: rem-calc($font-size-h1);
    font-weight: 100;
    margin: 0;
    margin-bottom: rem-calc(30px);
    @media (max-width: $sm-viewport) {
      font-size: rem-calc(32px);
    }
  }
  &__h2 {
    font-family: $font-family-headline;
    font-size: rem-calc($font-size-h2);
    font-weight: 600;
    margin: 0;

    &--alt {
      font-size: rem-calc(32px);
      font-weight: 100;
      margin: 0;
      margin-bottom: rem-calc(30px);
    }
    &--list {
      font-size: rem-calc(32px);
      font-weight: 600;
      margin: 0;
      line-height: 1.2;
      margin-bottom: rem-calc(8px);
      @media (max-width: $sm-viewport) {
        font-size: rem-calc(24px);
      }
    }
  }
  &__h2__subheadline {
    font-family: $font-family-headline;
    font-size: rem-calc(12px);
    font-weight: 400;
    margin: 0;
    &--list {
      font-size: rem-calc(14px);
      font-weight: 400;
      margin: 0;
    }
  }
  &__h3 {
    font-family: $font-family-headline;
    font-size: rem-calc($font-size-h3);
    font-weight: 600;
    margin: 0;
    margin-bottom: rem-calc(20px);
    &__extras {
      font-family: $font-family-headline;
      font-size: rem-calc($font-size-h3);
      font-weight: 600;
      margin: 0;
    }
    &__modal {
      font-weight: 400;
      margin-bottom: rem-calc(30px);
    }
  }
  &__h3__subheadline {
    font-size: rem-calc(14px);
    font-weight: 400;
    margin: 0;
    margin-bottom: rem-calc(20px);
  }
  &__h4 {
    font-size: rem-calc($font-size-h4);
    font-weight: 600;
    margin: 0;
    margin-bottom: rem-calc(10px);
    &.footer {
      color: $orm_txt_light_color;
      margin-bottom: rem-calc(14px);
    }
  }
}

small {
  font-size: 13px;
}

.orm-textlink {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.orm-squarelist {
  margin: 0;
  padding: 0 0 0 14px;
  &__item {
    list-style: square;
    padding-left: 10px;
    margin-bottom: 8px;
  }
}

.orm-nonenstyledlist {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 1rem;
  }
}
