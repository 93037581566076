.orm-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  z-index: 5000;
  &__inner {
    background-color: $white;
    border-radius: $border-radius-big;
    max-height: 500px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: auto;

    &--fullheight {
      max-height: none;
    }
  }

  &--overflowAuto {
    .orm-modal__inner {
      overflow: unset;
    }
    .orm-stationselect__options {
      z-index: 501;
    }
  }
}

.orm-modal--stationtime {
  .orm-modal__inner {
    background-color: $orm_searchbar_bg;
  }
  .orm-stationselect__label {
    color: $orm_searchbar_color;
  }

  .orm-searchbar__headline {
    color: $orm_searchbar_color;
  }
  .btn__small--black {
    color: $orm_searchbar_color;

    &:hover {
      filter: invert($orm_searchbar_color);
      color: invert($orm_searchbar_color);
    }
  }
}
// Styles für contact modal
// $border-radius-big: 0;

.orm-contactmodal__bg {
  background-color: rgba(0, 0, 0, 0.45);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}
.orm-contactmodal {
  background: $orm_white;
  border-radius: $border-radius-big;
  position: fixed;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  width: 1320px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
}

.orm-contactmodal__header {
  padding: 20px;
  border-radius: $border-radius-big $border-radius-big 0 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: $orm_reservation_bg;
}

.orm-contactmodal__footer {
  // margin-top: 20px;
  // padding: 20px;
  border-radius: 0 0 $border-radius-big $border-radius-big;
  background-color: $orm_reservation_bg;
  min-height: rem-calc(20px);
}

.orm-contactmodal__content {
  background: $orm_white;
  padding: 20px 40px 60px 40px;
  color: #333;
  overflow: auto;
  max-height: calc(100vh - 155px);
  @media (max-width: "576px") {
    padding: 20px 20px 60px 20px;
  }
}

.orm-contactmodal-vline {
  border-right: 1px solid #adadad;
  @media (max-width: "768px") {
    border: none;
    margin-bottom: rem-calc(30);
  }
}

.orm-contactmodal__adress {
  margin-bottom: rem-calc(16px);
  margin-top: rem-calc(8px);
  display: flex;
  align-items: flex-start;
  i {
    margin-right: rem-calc(8px);
    margin-top: 3px;
  }
  p {
    font-size: rem-calc(14px);
    margin: 0;

    span {
      font-size: rem-calc(16px);
      font-weight: 600;
    }
  }
}

.orm-contactmodal__times {
  margin-bottom: rem-calc(16px);
  margin-top: rem-calc(8px);
  display: flex;
  align-items: flex-start;
  i {
    margin-right: rem-calc(8px);
    margin-top: 3px;
  }
  p {
    font-size: rem-calc(14px);
    margin: 0;

    span {
      font-size: rem-calc(16px);
      font-weight: 600;
    }
  }
}
.orm-contactmodal__phone {
  margin-bottom: rem-calc(16px);
  margin-top: rem-calc(8px);
  display: flex;
  align-items: flex-start;
  i {
    margin-right: rem-calc(8px);
    margin-top: 3px;
  }
  p {
    font-size: rem-calc(14px);
    margin: 0;

    span {
      font-size: rem-calc(16px);
      font-weight: 600;
    }
  }
}

.orm-contactmodal__select {
  margin-bottom: rem-calc(40px);
  .orm-select {
    label {
      font-size: rem-calc(14px);
      margin-bottom: rem-calc(16px);
    }
  }
}
.orm-contactmodal__car {
  p {
    font-size: rem-calc(14px);
    b {
      font-size: rem-calc(16px);
    }
  }
  margin-bottom: rem-calc(40px);
}

.orm-contactmodal__inputs {
  margin-bottom: rem-calc(24px);
  .orm-input {
    margin-bottom: rem-calc(16px);
  }
}

.orm-contactmodal__message {
  margin-bottom: rem-calc(24px);
  .orm-input {
    margin-bottom: rem-calc(16px);
    label {
      font-size: rem-calc(14px);
      margin-bottom: rem-calc(16px);
    }
  }
}
