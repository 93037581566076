.orm-button__cta {
  display: inline-block;
  padding: rem-calc(14px 25px);
  font-size: rem-calc(14px);
  font-weight: 600;
  font-family: $font-family-buttons;
  background-color: $orm_cta_button_color;
  color: $orm_cta_button_text_color !important;
  text-decoration: none;
  border-radius: $border-radius-buttons;
  transition: all 0.5s ease;
  margin-bottom: rem-calc(7px);
  max-width: 100%;
  border: 0 none;
  cursor: pointer;

  @media (max-width: "420px") {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: rgba($orm_cta_button_color, 0.3);
  }
  &.small {
    padding: rem-calc(9px 28px);
    @media (max-width: "420px") {
      width: 100%;
      max-width: 55%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.outline {
    background-color: transparent;
    border: 2px solid $orm_cta_button_outline_color;
    color: $orm_cta_button_outline_color !important;
    &:hover {
      border-color: $orm_cta_button_outline_border_hover;
      background-color: $orm_cta_button_outline_hover_color;
      color: $orm_cta_button_outline_hover_text_color !important;
    }
    &:focus {
      outline: none;
    }
  }
  &.wide {
    padding-top: rem-calc(12.5px);
    padding-bottom: rem-calc(12.5px);
    width: 100%;
    max-width: rem-calc(212px);
    @media (max-width: "420px") {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    &.orm__cta {
      max-width: 100%;
      @media (min-width: "420px") {
        max-width: rem-calc(260px);
      }
    }
  }
  &:hover {
    background-color: $orm_cta_button_hover_color;
  }
  &.orm-btn__block {
    width: 100%;
  }
}

.orm-button__cta2 {
  display: inline-block;
  padding: rem-calc(14px 25px);
  font-size: rem-calc(14px);
  font-weight: 600;
  font-family: $font-family-buttons;
  background-color: $orm_cta2_button_color;
  color: $orm_txt_dark_color !important;
  text-decoration: none;
  border-radius: $border-radius-buttons;
  transition: all 0.5s ease;
  margin-bottom: rem-calc(7px);
  max-width: 100%;
  border: 0 none;
  cursor: pointer;

  @media (max-width: "420px") {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: rgba($orm_cta2_button_color, 0.3);
  }
  &.small {
    padding: rem-calc(9px 28px);
    @media (max-width: "420px") {
      width: 100%;
      max-width: 55%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.outline {
    background-color: transparent;
    border: 2px solid $orm_cta2_button_outline_color;
    color: $orm_cta2_button_outline_color !important;
    &:hover {
      border-color: $orm_cta2_button_outline_border_hover;
      color: $orm_cta2_button_outline_hover_color !important;
    }
    &:focus {
      outline: none;
    }
  }
  &.wide {
    padding-top: rem-calc(14px);
    padding-bottom: rem-calc(14px);
    width: 100%;
    max-width: rem-calc(212px);
    @media (max-width: "420px") {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    &.orm__cta {
      max-width: 100%;
      @media (min-width: "420px") {
        max-width: rem-calc(260px);
      }
    }
  }
  &:hover {
    background-color: $orm_cta2_button_hover_color;
    color: $orm_txt_dark_color;
  }
  &.orm-btn__block {
    width: 100%;
  }
}

.btn__small {
  &--black {
    transition: all 0.5s ease;
    border-radius: $border-radius-buttons;
    text-decoration: none;
    padding: rem-calc(4px 12px);
    font-size: rem-calc(14px);
    background-color: transparent;
    color: $orm_black !important;
    cursor: pointer;
    &:hover {
      background-color: $orm_black;
      color: $orm_white !important;
    }
    &:focus {
      outline: none;
    }
  }
  &--topright {
    background-color: transparent;
    position: absolute;
    top: 16px;
    right: 16px;
    color: $orm_searchbar_color !important;
  }
}

.orm-btn--icon {
  background: none;
  background-color: $orm_white;

  border-radius: $border-radius-buttons;
  text-decoration: none;
  color: $orm_txt_dark_color;
  font-family: inherit;
  font-size: 14px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s,
    border-color ease-in-out 0.25s;
  @media (min-width: $md-viewport) {
    border: 1px solid $orm_form_border_color;
    padding: 9px 14px;
    text-align: left;
  }
  @media (max-width: $md-viewport) {
    width: 36px;
    height: 36px;
    font-size: 22px;
  }
  i {
    @media (min-width: $md-viewport) {
      margin-right: 6px;
    }
  }

  span {
    @media (max-width: $md-viewport) {
      display: none;
      border: 0;
    }
  }

  &:hover,
  &-active {
    background-color: $orm_txt_dark_color;
    border-color: $orm_txt_dark_color;
    color: $orm_white;
    @media (max-width: $md-viewport) {
      background-color: $orm_white;
      color: $orm_txt_dark_color;
      box-shadow: 0px 0px 4px #999;
    }
  }
}

.orm-btn--logout {
  background: none;
  background-color: $orm_white;

  border-radius: $border-radius-buttons;
  text-decoration: none;
  color: $orm_txt_dark_color;
  font-family: inherit;
  font-size: 14px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s,
    border-color ease-in-out 0.25s;

  border: 1px solid $orm_form_border_color;
  padding: 9px 14px;
  text-align: left;

  i {
    margin-right: 6px;
  }

  &:hover,
  &-active {
    background-color: $orm_txt_dark_color;
    border-color: $orm_txt_dark_color;
    color: $orm_white;
  }
}

.orm-button__cta--offer {
  background-color: $customer_color_cta2;

  &:hover,
  &:focus,
  &:visited {
    background-color: darken($customer_color_cta2, 8%);
  }

  &-icon {
    &:before {
      position: relative;
      top: -2px;
      right: -6px;
    }
  }
}
