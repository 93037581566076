//GLOBAL - START

$border-radius-big: 20px;
$border-radius-small: 5px;
$border-radius-buttons: 4px;

$global-font-size: 16px;
$global-line-height: 1.4;

//GLOBAL - END

// FONTS - START

$font-family: "Open Sans", Helvetica, Arial;
$font-family-headline: "Open Sans", Helvetica, Arial;
$font-family-buttons: "Open Sans", Helvetica, Arial;

// FONTS - END

//BASE COLORS - START

$orm_red: #de1f21; // IMPORTANT ERROR CLASSES OVERWRITE DEFAULT
$orm_green: #18cb69; // IMPORTANT ERROR CLASSES OVERWRITE DEFAULT
$orm_black: #000000;

$customer_color_text: #ffffff;
$customer_color_text_dark:#000000;

$customer_color_grey_dark:#000000; //#1a171b;
$customer_color_grey_md: #d3d6d8; //#1a171b;
$customer_color_grey_light: #f7f7f7; //#1a171b;
$customer_color_primary:#e81f76;
$customer_color_secondary: #ff0000;

$customer_color_cta2:#e81f76;
$orm_cta2_button_text_color: #fff;

$bgcta1: $grey !default;
$bgcta2: $grey-light !default;
$bgcta3: $grey-dark !default;

$customer_color_white: #fff;

$customer_bg_color:#000000;
$customer_bg_color_secondary:#121212;

$customer_input_border:#ffffff;
$customer_input_color:#000000;
$customer_input_background:#ffffff;

$customer_input_border_res:#ffffff;
$customer_input_color_res:#000000;
$customer_input_background_res:#ffffff;

$customer_color_res_steps:$customer_color_white;

$customer_seatch_location_fontcolor: $customer_color_text;

//used in Locationmap

$backgroundcta1: $customer_color_grey_light;
$backgroundcta2: $customer_color_white;
//BASE COLORS - END

// TEXT COLOR - START

$orm_txt_dark_color: $customer_color_text_dark;
$orm_txt_light_color: $customer_color_text;

$orm_txt_headline_searchbar: $customer_color_text;
$orm_txt_headline_searchbar_dark: $customer_color_text_dark;

$orm_txt_headline_locationmap: $customer_color_text;
$orm_txt_headline_locationmap_dark: $customer_color_text_dark;

//TEXT COLOR - END

//BACKGROUND COLOR - START

$orm_bg_dark_color: $customer_color_grey_dark;
$orm_bg_lt_grey_color: $customer_color_grey_light;
$orm_bg_light_color: $orm_white;

//BACKGROUND COLOR - END

//BUTTONS - START
$orm_cta_button_color: $customer_color_primary;
$orm_cta_button_hover_color: darken($customer_color_primary, 5%);

$orm_cta_button_text_color:#ffffff;

$orm_cta_button_outline_color: $customer_color_primary;
$orm_cta_button_outline_hover_color: $customer_color_primary;
$orm_cta_button_outline_border_hover: $customer_color_primary;
$orm-cta-button-outline-hover-text-color: $customer_color_white;

//Login btn
$orm_btn_login_bg_color: $orm_bg_dark_color;
$orm_btn_login_color: $orm_txt_light_color;

$orm_cta2_button_color: $customer_color_secondary;
$orm_cta2_button_hover_color: $customer_color_secondary;

$orm_cta2_button_outline_color: $customer_color_secondary;
$orm_cta2_button_outline_hover_color: $customer_color_white;
$orm_cta2_button_outline_border_hover: $customer_color_secondary;

//BUTTONS - END

//SEARCHBAR - START
$orm_searchbar_bg: $customer_bg_color;
$orm_searchbar_color: $customer_seatch_location_fontcolor;

$orm_searchbar_tab_inactive:#121212;
$orm_searchbar_tab_inactive_text:#ffffff;
//// INPUTS
$orm_search_input_bg: $customer_input_background;
$orm_search_input_color: $customer_input_color;
$orm_search_input_border: $customer_input_border;
//SEARCHBAR - ENDE

//// RESERVATION
$orm_reservation_input_border: $customer_input_border_res;
$orm_reservation_input_bg: $customer_input_background_res;
$orm_reservation_input_color: $customer_input_color_res;

//SEARCHBAR - END

//MAP - START
$orm_map_stationlist_bg1: $customer_bg_color;
$orm_map_stationlist_color1: $customer_seatch_location_fontcolor;

$orm_map_stationlist_bg2: $customer_bg_color_secondary;

$orm_map_stationlist_color2: $customer_seatch_location_fontcolor;

//// INPUT
$orm_map_input_bg: $customer_input_background;
$orm_map_input_color: $customer_input_color;
$orm_map_input_border: $customer_input_border;
//MAP - END

//MAP MARKER - START
$orm_map_marker_rental_color: $customer_color_grey_dark;
$orm_map_marker_sharing_color: $customer_color_secondary;
//MAP MARKER - END

//RESERVATION - START
$orm_header_bg: $customer_color_res_steps;

$orm_header_step_color:$customer_color_text_dark;
$orm_header_step_border:$customer_color_text_dark;

$orm_header_step_active_color:$customer_color_white;
$orm_header_step_active_bg:#000000;

$orm_header_step_checked_color:$customer_color_white;
$orm_header_step_checked_bg:#e81f76;

$orm_reservation_bg: $customer_color_grey_light;
$orm_reservation_inner_bg: $customer_color_white;
$orm_reservation_borderleft: $customer_color_grey_md;

//RESERVATION - END

//FOOTER - START

$orm_footer_bg: $customer_color_grey_dark;
$orm_footer_link_color: $orm_white;
$orm_footer_link_hover: $customer_color_primary;

//FOOTER - END

// OFFERS - START
$orm_offers_background: rgba($orm_black, 0.8);
$orm_offers_text: $customer_color_white;
$orm_offers_dots: $customer_color_secondary;
$orm_offers_dots_inner: $customer_color_secondary;
// OFFERS - END

//POSSIBLE OTHERS BUT WORTST CASE

// $orm_form_border_color: $orm_dk_grey;
// $orm_form_bg_color: $orm_white;
// $orm_form_text_inactive_color: $orm_dk_bluegrey;
// $orm_form_bg_inactive_color: $orm_lt_grey;
// $orm_form_checked_color: $orm_green;
// $orm_form_focus_color: $orm_black;
// $orm_form_placeholder_color: $orm_md_grey;
// $orm_form_error_color: $orm_red;
// $orm_calendar_selected_color: $orm_dk_bluegrey;
// $orm_calendar_not_available_color: $orm_md_grey;
// $orm_calendar_occupied_color: $orm_red;
// $orm_calendar_partially_available: $orm_orange;

// $orm_timepicker_selected: $orm_black;

// $orm_loading_bar: $orm_md_grey;
// $orm_loading_bar_border: $orm_dk_grey;
// $orm_loading_bar_progress: $orm_red;

figure.image {
  margin: 0;
  img {
    height: auto;
  }
}

.ce-image.ce-center, .ce-textpic.ce-center {
  .ce-row {
    display: flex;
    justify-content: space-around;
  }
}
