.orm-button__dropdown {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  //   &:after {
  //     content: "\f0d7";
  //     display: inline-block;
  //     margin-left: rem-calc(10px);
  //     font-family: "Font Awesome 5 Pro";
  //     vertical-align: bottom;
  //   }
}
.orm-filter__dropdown {
  position: absolute;
  background-color: $white;
  padding: rem-calc(16px) rem-calc(16px);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  margin-top: rem-calc(8px);
  z-index: 100;

  @media only screen and (max-width: $sm-viewport) {
    width: 90%;
    right: 5%;
  }
  &.closed {
    display: none;
  }
  &__item {
    margin-bottom: rem-calc(4px);
    // background-color: $orm_bg_lt_grey_color;
    // &:hover,
    // &:active,
    // &:focus {
    //   // background-color: $orm_bg_dark_color;
    //   // color: $white;
    // }
    a {
      display: block;
      text-decoration: none !important;
      padding: rem-calc(8px) rem-calc(8px);

      &:hover,
      &:active,
      &:focus,
      &.router-link-active {
        text-decoration: none;
        background-color: $orm_bg_dark_color;
        color: $white;
      }
    }
    // label {
    //   display: block;
    //   cursor: pointer;
    //   &:hover,
    //   &:active,
    //   &:focus {
    //     text-decoration: underline;
    //   }
    // }
    input {
      margin-right: rem-calc(8px);
    }
  }
}
.orm-filter__labels {
  display: inline-block;
  margin-left: rem-calc(16px);
}

.orm-filter__label {
  background-color: $orm_txt_dark_color;
  color: $orm_txt_light_color;
  font-size: rem-calc(13px);
  font-weight: 600;
  padding-left: rem-calc(10px);
  padding-right: rem-calc(10px);
  text-transform: uppercase;
  border-radius: 90px;
  margin-right: rem-calc(5px);
}
