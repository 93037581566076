.orm-crentFooter {
  background-color: $orm_footer_bg;

  padding-top: rem-calc(60px);
  padding-bottom: rem-calc(60px);
  * {
    color: $orm_txt_light_color;
  }
  .orm-linkblock {
    flex: 1;
    &.about {
      flex: 2;
    }
  }
  a {
    color: $orm_footer_link_color;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: $orm_footer_link_hover;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  li {
    font-size: rem-calc(14px);
    font-weight: 100;
    padding-top: rem-calc(4px);
    padding-bottom: rem-calc(5px);
  }
  p {
    font-size: rem-calc(14px);
    line-height: rem-calc(28px);
    font-weight: 300;
    margin: 0;
  }
}
