@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?un477m");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?un477m#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?un477m")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?un477m")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?un477m##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="orm_icon_"],
[class*=" orm_icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.orm_icon_pass {
  &:before {
    content: $orm_icon_pass;
  }
}
.orm_icon_gear {
  &:before {
    content: $orm_icon_gear;
  }
}
.orm_icon_luggage {
  &:before {
    content: $orm_icon_luggage;
  }
}
.orm_icon_co2 {
  &:before {
    content: $orm_icon_co2;
  }
}
.orm_icon_weight {
  &:before {
    content: $orm_icon_weight;
  }
}
.orm_icon_sitzer {
  &:before {
    content: $orm_icon_sitzer;
  }
}
.orm_icon_tuerer {
  &:before {
    content: $orm_icon_tuerer;
  }
}
.orm_icon_kombi {
  &:before {
    content: $orm_icon_kombi;
  }
}
.orm_icon_addblue {
  &:before {
    content: $orm_icon_addblue;
  }
}
.orm_icon_ahk {
  &:before {
    content: $orm_icon_ahk;
  }
}
.orm_icon_allrad {
  &:before {
    content: $orm_icon_allrad;
  }
}
.orm_icon_automatik {
  &:before {
    content: $orm_icon_automatik;
  }
}
.orm_icon_bluetooth {
  &:before {
    content: $orm_icon_bluetooth;
  }
}
.orm_icon_diesel {
  &:before {
    content: $orm_icon_diesel;
  }
}
.orm_icon_ganzjahresreifen {
  &:before {
    content: $orm_icon_ganzjahresreifen;
  }
}
.orm_icon_benzin {
  &:before {
    content: $orm_icon_benzin;
  }
}
.orm_icon_klima {
  &:before {
    content: $orm_icon_klima;
  }
}
.orm_icon_lkwkoffer {
  &:before {
    content: $orm_icon_lkwkoffer;
  }
}
.orm_icon_navi {
  &:before {
    content: $orm_icon_navi;
  }
}
.orm_icon_rollstuhlrampe {
  &:before {
    content: $orm_icon_rollstuhlrampe;
  }
}
.orm_icon_sitzheizung {
  &:before {
    content: $orm_icon_sitzheizung;
  }
}
.orm_icon_winterreifen {
  &:before {
    content: $orm_icon_winterreifen;
  }
}
