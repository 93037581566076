/* open-sans-300 - latin */

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-300.woff")
      format("woff"),
    /* Modern Browsers */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-300.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-regular.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: $fa-font-display;
  src: url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-600.woff")
      format("woff"),
    /* Modern Browsers */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$fontfolder}open-sans-v18-latin/open-sans-v18-latin-600.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-light-300.eot");
  src: url("#{$fa-font-path}/fa-light-300.eot") format("embedded-opentype"),
    url("#{$fa-font-path}/fa-light-300.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-light-300.woff") format("woff"),
    url("#{$fa-font-path}/fa-light-300.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-light-300.svg") format("svg");
}

.fal {
  font-family: "Font Awesome 5 Pro", Helvetica, Arial;
  font-weight: 300;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-regular-400.eot");
  src: url("#{$fa-font-path}/fa-regular-400.eot") format("embedded-opentype"),
    url("#{$fa-font-path}/fa-regular-400.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-regular-400.woff") format("woff"),
    url("#{$fa-font-path}/fa-regular-400.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-regular-400.svg") format("svg");
}

.far {
  font-family: "Font Awesome 5 Pro", Helvetica, Arial;
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-solid-900.eot");
  src: url("#{$fa-font-path}/fa-solid-900.eot") format("embedded-opentype"),
    url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-solid-900.woff") format("woff"),
    url("#{$fa-font-path}/fa-solid-900.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-solid-900.svg") format("svg");
}

.fa,
.fas {
  font-family: "Font Awesome 5 Pro", Helvetica, Arial;
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-brands-400.eot");
  src: url("#{$fa-font-path}/fa-brands-400.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fa-font-path}/fa-brands-400.woff2") format("woff2"),
    url("#{$fa-font-path}/fa-brands-400.woff") format("woff"),
    url("#{$fa-font-path}/fa-brands-400.ttf") format("truetype"),
    url("#{$fa-font-path}/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}
