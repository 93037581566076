$font-family: "Open Sans", Helvetica, Arial;
$font-family-headline: "Open Sans", Helvetica, Arial;
$font-family-buttons: "Open Sans", Helvetica, Arial;

$font-size-h1: 42px;
$font-size-h2: 24px;
$font-size-h3: 22px;
$font-size-h4: 16px;

$grid-breakpoints: (
  xs: 30em,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1320px,
);

/// MARGIN AND PADDINGs
/// MARGIN PADDING ORM FUNKTION
// orm-p&
// t - for classes that set margin-top or padding-top
// b - for classes that set margin-bottom or padding-bottom
// l - for classes that set margin-left or padding-left
// r - for classes that set margin-right or padding-right
// x - for classes that set both *-left and *-right
// y - for classes that set both *-top and *-bottom

$padding-sizes: 0, 8, 10, 16, 24, 30, 32, 36, 40, 56, 60, 70, 128; // in px
$padding-sizes-sm: 0, 8, 10, 16, 24, 30, 32, 36, 40, 60, 70, 128; // in px
$padding-sizes-md: 0, 8, 10, 16, 24, 30, 32, 36, 40, 60, 70, 128; // in px
$padding-sizes-lg: 0, 8, 10, 16, 24, 30, 32, 36, 40, 60, 70, 128; // in px

$margin-sizes: 0, 8, 16, 24, 32, 48, 56, 64, 94, 128; // in px
$margin-sizes-sm: 0, 8, 16, 24, 32, 48, 56, 64, 94, 128; // in px
$margin-sizes-md: 0, 8, 16, 24, 32, 48, 56, 64, 94, 128; // in px
$margin-sizes-lg: 0, 8, 16, 24, 32, 48, 56, 64, 94, 128; // in px

///BSP: .orm-mb-16 .orm-mb-md-32

/* COLOURS */

$font-color: black;
$blue: #06c;
$yellow: #fc0;
$white: #fff;
$black: #000;
$grey: #c6c7cb;
$grey-dark: #26272a;
$grey-light: #f7f7f7;

$btn-primary: $grey-dark;

$customer_color_cta2: #01843c;

$bgcta1: $grey !default;
$bgcta2: $grey-light !default;
$bgcta3: $grey-dark !default;

/* Vuelendar Variables*/

//
// Base colors variables
//
$vl-primary: #000000 !default;
$vl-white: rgb(255, 255, 255);
$vl-black: #3e3a4e !default;
$vl-gray-0: #a0a0a0 !default;
$vl-gray-1: #b5b5b5 !default;
$vl-gray-2: #f6f6f6 !default;

//
// Specific colors for stuff
//
$vl-calendar-text-color: $vl-black !default;
$vl-calendar-bg-color: $vl-white !default;

$vl-weekday-text-color: $vl-primary !default;

$vl-selected-day-bg-color: $vl-primary !default;
$vl-selected-day-text-color: $vl-white !default;
$vl-selected-disabled-day-bg-color: lighten($vl-primary, 15%) !default;
$vl-selected-disabled-day-text-color: $vl-white !default;
$vl-selected-disabled-border: 0px solid $vl-black !default;

//
// Sizes
//
$vl-calendar-month-width: 242px !default; //275px

//
// Paddings
//
$vl-calendar-month-padding: 0 20px !default;
$vl-calendar-padding: 10px 0 0 !default;

//
// LOCATIONMAP
//
$border-radius-big: 20px;
$border-radius-small: 5px;
$border-radius-buttons: 4px;

$global-font-size: 16px;
$global-line-height: 1.4;

$map_icon_rent: #25313e;
$map_icon_share: #00a0bf;
$map_icon_abo: #084893;
$map_icon_special: #ea941e;
$map_icon_selected: #e42022;
