.orm-locationMap {
  .vl-calendar {
    top: 0 !important;
  }
  &__title {
    text-align: center;
    font-size: rem-calc(42px);
    font-weight: 100;
    margin-top: rem-calc(72px);
    margin-bottom: rem-calc(30px);
    color: $orm_txt_headline_locationmap;
    @media (max-width: $sm-viewport) {
      font-size: rem-calc(32px);
      margin-top: rem-calc(56px);
    }

    &--dark {
      color: $orm_txt_headline_locationmap_dark;
    }
  }
  &__mapContainer {
    border-radius: $border-radius-big $border-radius-big 0 0;

    overflow: auto;
    @media only screen and (min-width: $md-viewport) {
      border-radius: $border-radius-big 0 0 0;
    }

    &_nolegend {
      @media only screen and (min-width: $md-viewport) {
        border-radius: $border-radius-big 0 0 $border-radius-big;
      }
    }
  }
  &__stationsList {
    background-color: $orm_map_stationlist_bg1;
    height: 450px;

    overflow: auto;
    &__container {
      max-height: 450px;
      border-radius: 0;
      @media only screen and (min-width: $md-viewport) {
        border-radius: 0 $border-radius-big 0 0;
      }
    }
  }
}

.orm-locationMap__stationlist_nolegend {
  border-radius: 0 0 $border-radius-big $border-radius-big;
  @media only screen and (min-width: $md-viewport) {
    border-radius: 0 $border-radius-big $border-radius-big 0;
  }
}

.orm-stationsList {
  &__button {
    padding-top: rem-calc(8px);
    padding-bottom: rem-calc(8px);
    @media (min-width: "421px") and (max-width: $md-viewport) {
      text-align: right;
      .orm-btn__block {
        max-width: rem-calc(250px);
      }
    }
  }
  &__station {
    padding: rem-calc(16px);
    background-color: $orm_map_stationlist_bg1;
    color: $orm_map_stationlist_color1;
    &:nth-child(even) {
      background-color: $orm_map_stationlist_bg2;
      color: $orm_map_stationlist_color2;
    }

    &:not(.selected) {
      cursor: pointer;
    }
    .orm-station__title {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        padding-left: rem-calc(12px);
        font-size: rem-calc(15px);
        font-weight: 600;
      }
      margin-bottom: rem-calc(4px);
    }
    .orm-station__name__container {
      display: flex;
      justify-content: space-between;
    }
    .orm-station__name {
      font-size: rem-calc(15px);
      font-weight: 600;
      width: calc(100% - 20px);
    }
    .orm-station__street {
      font-size: rem-calc(12px);
      font-weight: 400;
    }
    .orm-station__icons {
      .orm-car__rental {
        color: $map_icon_rent;
      }
      .orm-car__share {
        color: $map_icon_share;
      }
      .orm-car__special {
        color: $map_icon_special;
      }
      .orm-car__abo {
        color: $map_icon_abo;
      }
    }
    .orm-station__distance {
      position: absolute;
      right: rem-calc(16px);
      text-align: right;
      top: rem-calc(34px);
      small {
        font-size: rem-calc(12px);
      }
    }
    .orm-station__address {
      background-color: $orm_bg_light_color;
      border: 1px solid $orm_txt_dark_color;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      padding: rem-calc(4px) rem-calc(12px);
      margin-bottom: rem-calc(8px);
      max-width: rem-calc(226px);
      .orm-station__address__icon {
        font-size: rem-calc(20px);
        align-self: flex-end;
      }
    }
    .orm-station__alt__text {
      display: inline-block;
      font-size: rem-calc(12px);
      margin-bottom: rem-calc(22px);
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-size: rem-calc(17px);
        font-weight: 100;
        content: "\f0c8";
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        margin-right: 5px;
      }
      &:hover {
        &:before {
          content: "\f14a";
        }

        cursor: pointer;
      }
    }
  }
}

.orm-locationMap__stationsList {
  .orm-stationselect,
  .orm-dateselect {
    background-color: $orm_map_input_bg;
    color: $orm_map_input_color;
  }
  .orm-stationselect__options {
    z-index: 800;
  }
}
.orm-locationmap__legend {
  background-color: $orm_map_stationlist_bg1;
  border-radius: 0 0 $border-radius-big $border-radius-big;
  color: $orm_map_stationlist_color1;
}
.orm-lenged__items {
  padding: 4px 16px;
}
//does not work with colors containing alpha
@function encodecolor($string) {
  @if type-of($string) == "color" {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: "%23" + $string;
  @return $string;
}

@mixin svg-background($color) {
  background-image: url("data:image/svg+xml,%3Csvg width='12px' height='16px' viewBox='0 0 12 16' version='1.1' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath id='m' d='M5.383 15.677l-1.02 -1.47c-3.61 -5.175 -4.347 -5.965 -4.363 -8.135l0 -0.072c0 -3.314 2.686 -6 6 -6c3.276 0 5.939 2.626 5.999 5.887l0.001 0.113c-0 2.419 -0.843 3.095 -5.383 9.677c-0.298 0.431 -0.936 0.431 -1.234 0Z' style='fill:#{$color}%3Bfill-rule:nonzero%3B'/%3E%3C/svg%3E");
}

.orm-lenged__item {
  font-size: 14px;
  padding: 4px 16px;
  display: inline-block;
  &_icon {
    width: 12px;
    height: 16px;
    display: inline-block;
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
    margin-right: 4px;
  }
  &_RENT {
    i {
      @include svg-background(encodecolor($map_icon_rent));
    }
  }
  &_SHARE {
    i {
      @include svg-background(encodecolor($map_icon_share));
    }
  }
  &_ABO {
    i {
      @include svg-background(encodecolor($map_icon_abo));
    }
  }
  &_SPECIAL {
    i {
      @include svg-background(encodecolor($map_icon_special));
    }
  }
  &_selected {
    i {
      @include svg-background(encodecolor($map_icon_selected));
    }
  }
}

.orm-searchlocmap {
  margin: rem-calc(-24px) rem-calc(-8px);
  @media only screen and (min-width: $sm-viewport) {
    margin: rem-calc(-24px) rem-calc(-16px);
  }

  &--radius {
    border-top-left-radius: $border-radius-big;
    border-top-right-radius: $border-radius-big;
  }
}
