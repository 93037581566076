.orm-priceCalculator {
  text-align: right;

  padding-top: rem-calc(16px);
  margin-bottom: rem-calc(72px);
  @media (min-width: $md-viewport) {
    padding-top: rem-calc(72px);
  }
}
.orm-pricetag {
  text-align: end;
  font-size: rem-calc(20px);
  font-weight: 400;
  margin: 0;
  em {
    font-weight: 600;
    font-style: normal;
  }
  @media (max-width: $lg-viewport) {
    margin-top: rem-calc(32px);
  }
  flex: 0 0 auto;
}
.orm-price-item {
  display: flex;
  text-align: start !important;
}
.orm-price_item_right {
  text-align: end;
  font-weight: 400;
  flex: 0 0 auto;
}

.orm-price-item span {
  flex: 1;
}

.orm-smallprint {
  font-size: rem-calc(12px);
  font-weight: 400;
  margin: 0;
  margin-bottom: rem-calc(36px);
}
.flex-container {
  display: flex;
  align-items: center;
}

.description {
  flex: 1 1 auto !important; /* Flex-grow and flex-shrink with automatic basis */
  margin-right: 10px; /* Optional: adds some space between the description and the price */
  em {
    font-weight: 600;
    font-style: normal;
  }
}

.orm-price__right {
  text-align: end;
  font-weight: 400;
  // @media (max-width: $sm-viewport) {
  //   margin-top: rem-calc(16px);
  //   margin-bottom: rem-calc(16px);
  // }
}
.orm-input__right {
  text-align: start;
  font-weight: 400;
  // @media (max-width: $sm-viewport) {
  //   margin-top: rem-calc(16px);
  //   margin-bottom: rem-calc(16px);
  // }
  .orm-withIcon__wide {
    margin-left: 0;
    margin-right: -16px;
    margin-top: 8px;
  }
  @media (min-width: $sm-viewport) {
    text-align: end;

    .orm-withIcon__wide {
      margin-top: 0;
    }
  }
}
.orm-valignCenter {
  align-items: center;
}

.orm-icon__right {
  text-align: end;
  font-weight: 400;
}

.orm-carimage {
  text-align: center;
}
.orm-carequip {
  &__centered {
    text-align: center;
    // margin-bottom: rem-calc(20px);
  }
  .orm-equip__txt__icons {
    margin: 0;
    margin-bottom: rem-calc(8px);
    font-size: rem-calc(12px);
    font-weight: 400;

    .orm-equip__icon {
      margin-right: rem-calc(12px);
      display: inline-block;
    }
    .orm_icontext_additional {
      position: relative;
      top: -1px;
    }
  }
  .orm-equip__txt__freemiles {
    margin: 0;
    font-size: rem-calc(12px);
    font-weight: 400;
  }
}

.orm-cardescription {
  padding-top: rem-calc(8px);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: rem-calc(16px);
    font-weight: 600;
  }
  ul {
    margin: 0;
    margin-top: rem-calc(8px);
    list-style: none;
    padding-left: 0;
    li {
      padding-bottom: rem-calc(2px);
    }
  }
}
.orm-border-left {
  @media (min-width: $sm-viewport) {
    border-left: 2px solid $orm_reservation_borderleft;
    padding-left: 30px;
    min-height: 250px;
  }
}

.orm-border-right {
  @media (min-width: $md-viewport) {
    border-right: 2px solid $orm_reservation_borderleft;
    padding-right: rem-calc(40px);
    // max-height: 320px;
  }
}

.orm-subtotal {
  text-align: end;
  font-weight: 600;
  margin-top: rem-calc(16px);
}

.orm-list__element {
  .orm-list__bold {
    font-weight: 600;
  }
  input {
    font-weight: 600;
  }
  & .orm-small {
    font-size: 0.875em;
    font-weight: 400;
  }
  & .orm-extra-small {
    font-size: 11px;
    font-weight: 400;
  }
  & .orm-tarif-km {
    @media (max-width: $sm-viewport) {
      margin-bottom: rem-calc(16px);
      margin-top: rem-calc(8px);
    }
  }
}
.orm-extras__button {
  font-size: rem-calc(32px);
  cursor: pointer;
  &__closed {
    color: $orm_green;
  }
  &__open {
    color: $orm_red;
  }
}
.orm-extras__container {
  &__closed {
    display: none;
  }
}
.orm-legalNotice {
  font-size: rem-calc(14px);
  font-weight: 400;
  max-width: rem-calc(580px);
}

.orm-cargroup__container {
  @media (max-width: $sm-viewport) {
    margin-top: rem-calc(16px);
    margin-bottom: rem-calc(32px);
  }
}

/* Tooltip container */
.orm-cargroup__tooltip {
  display: inline-block;
  cursor: pointer;
  border-radius: 90px;
  transition:
    color 0.3s linear,
    background-color 0.3s linear;
  margin-left: 0.2rem;
}

/* Tooltip text */
.orm-cargroup__tooltip .orm-cargroup__tooltip_text {
  visibility: hidden;
  opacity: 0;
  width: 260px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-family: "Open Sans";
  font-size: rem-calc(12px);
  font-weight: 400;
  line-height: 1.5;
  transition:
    visibility 0.3s linear,
    opacity 0.3s linear;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.orm-cargroup__tooltip:hover {
  .orm-cargroup__tooltip_text {
    visibility: visible;
    opacity: 1;
  }
  color: #fff;
  background-color: #000;
  border-radius: 90px;
}
.orm-cargroup__tooltip-bottom {
  top: 135%;
  left: 50%;
  margin-left: -120px;
  &--list {
    top: 75%;
    left: 33%;
    margin-left: -120px;
  }
}

.orm-voucher__error {
  position: absolute;
  right: 6px;
  margin-top: 4px;
  color: $orm_red;
  font-size: 14px;
}
