.orm-html img {
  max-width: 100%;
}

.overflow {
  &Hidden {
    overflow: hidden;
  }
  &Auto {
    overflow: auto;
  }
}

.orm-dblock {
  display: block;
}

.borderRadius {
  &TBR {
    border-radius: 0 $border-radius-big $border-radius-big 0;
  }
  &TBRL {
    border-radius: $border-radius-big $border-radius-big $border-radius-big $border-radius-big;
  }
  &__small {
    border-radius: $border-radius-small;
  }
}

.orm-borderRadius {
  &TBR {
    border-radius: 0 $border-radius-big $border-radius-big 0;
  }
  &TBRL {
    border-radius: $border-radius-big $border-radius-big $border-radius-big $border-radius-big;
  }
  &__small {
    border-radius: $border-radius-small;
  }
}

.orm-border__left {
  @media (min-width: $sm-viewport) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 120%;
      width: 1px;
      background-color: $orm_form_border_color;
      left: rem-calc(-8px);
      top: rem-calc(-8px);
    }
  }
  // border-left: 1px solid $orm_form_border_color;
}

.orm-background__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.orm-fontSize-5vw {
  font-size: 5vw;
}
.text {
  &-center {
    text-align: center;
    &-lg {
      @media (min-width: $lg-viewport) {
        text-align: center;
      }
    }
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
    &-sm {
      @media (min-width: $sm-viewport) {
        text-align: right;
      }
    }
  }
  &-small {
    font-size: rem-calc(15px);
  }
}
.ce-headline-center {
  text-align: center;
}
.float {
  &-left {
    float: left;
  }
  &-right {
    float: right;
  }
}

.orm-relative {
  position: relative;
}

.orm-background {
  &--white {
    background-color: $white;
  }
  &--grey {
    background-color: $orm_bg_lt_grey_color;
  }
  &--greytowhite {
    background-color: $white;
    background: linear-gradient(180deg, rgba($customer_color_text_dark, 0.1) 0%, $white 7%, $white 100%);
  }
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}
.orm-no-padding-inner {
  > div[class^="orm-col-"],
  > div[class*=" orm-col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;

  > div[class^="orm-col-"],
  > div[class*=" orm-col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.-orm-ml-md-4 {
  @media (min-width: $md-viewport) {
    margin-left: -4px;
  }
}

.orm-h100 {
  height: 100%;
  &vh {
    height: 100vh;
  }
}
// .orm-px128 {
//   padding-left: rem-calc(128px);
//   padding-right: rem-calc(128px);
// }
// .orm-pb60 {
//   padding-bottom: rem-calc(60px);
// }
// .orm-pb70 {
//   padding-bottom: rem-calc(70px);
// }

.orm-rotate360 {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // width: 120px;
  // height: 120px;
  // margin:-60px 0 0 -60px;
  // -webkit-animation: spin 1s linear infinite;
  // -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  &--big {
    font-size: 50px;
    margin: 50px;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.show-mobile-only {
  display: none;
  @media (max-width: $sm-viewport) {
    display: block;
  }
}

.hide-mobile-only {
  display: block;
  @media (max-width: $sm-viewport) {
    display: none;
  }
}
.show-md-down {
  display: none;
  @media (max-width: $md-viewport) {
    display: unset;
  }
}
.hide-md-down {
  @media (max-width: $md-viewport) {
    display: none;
  }
}
.orm-eq-h {
  @media only screen and (min-width: $sm-viewport) {
    height: 100%;
  }
}

.justify {
  &-space-around {
    justify-content: space-around;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-space-evenly {
    justify-content: space-evenly;
  }
  &-flex-start {
    justify-content: flex-start;
  }
  &-flex-end {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
}

.orm-uppercase {
  text-transform: uppercase;
}

.orm-cursor--pointer {
  cursor: pointer;
}

.orm-w100 {
  width: 100% !important;
  min-width: 100% !important;
}

.right-0 {
  right: 0;
}

.orm-color {
  &--blue {
    color: $orm_blue;
  }
  &--red {
    color: $orm_red;
  }
}

.noBreak {
  white-space: nowrap;
}

.textTrim {
  text-overflow: ellipsis;
  display: block;
}

.orm-inlineBlock {
  display: inline-block;
}
