// .orm-customer-widget {
//   position: relative;
// }
// .orm-login-widget {
//   position: absolute;
//   background: #fff;
//   z-index: 500;
//   width: 300px;
//   height: 300px;
//   top: 20px;
//   right: 0;
// }

.orm-customer-widget {
  position: relative;
  display: inline-block;
}

.orm-login-widget {
  padding: rem-calc(20px) rem-calc(20px) rem-calc(30px) rem-calc(20px);
  background: #fff;
  z-index: 500;
  width: rem-calc(300px);
  // height: 300px;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: $border-radius-big;

  position: absolute;
  top: rem-calc(46px);
  right: 0;

  .orm-input {
    margin-bottom: rem-calc(12px);
  }
  .orm-pwdlink {
    font-size: rem-calc(10px);
    margin-top: -rem-calc(4px);
    margin-bottom: rem-calc(20px);
    text-align: center;

    p {
      text-decoration: underline;
      cursor: pointer;
      margin: 0;
      display: inline-block;
      transition: color 0.3s ease;
      &:hover {
        color: $orm_cta_button_hover_color;
      }
    }
  }
  .orm-btn {
    background-color: $orm_btn_login_bg_color;
    color: $orm_btn_login_color;
    width: 100%;
    padding: rem-calc(12px);
    transition: background-color 0.5s ease;
    &:hover {
      background-color: lighten($color: $orm_btn_login_bg_color, $amount: 10%);
    }
  }

  .orm-customer{
    display: flex;
    align-items: center;
  }
}

.orm-login-widget--typo3 .orm-login-widget {
  @media (max-width: $md-viewport) {
    width: calc(100vw - 32px);
    position: absolute;
    top: 52px;
    right: -44px;
  }
}
.orm-loginPage .orm-login-widget {
  position: relative;
  top: 0;
  left: 0;
  margin: auto;
}

.orm-login-headline {
  font-size: $font-size-h3;
  margin-bottom: rem-calc(20px);
  margin-top: 0;
}

.orm-btn--loggedin {
  border: none;
  background: transparent;
  font-size: rem-calc(14px);
  cursor: pointer;
  font-family: inherit;
  position: relative;
  display: inline-block;
  &--text {
    padding: rem-calc(9px) rem-calc(44px) rem-calc(9px) 0;
  }
  span {
    @media (max-width: $md-viewport) {
      display: none;
      border: 0;
    }
  }
}
.orm-login__icon--loggedin {
  width: rem-calc(30px);
  height: rem-calc(30px);
  display: inline-block;

  &--text {
    position: absolute;
    top: rem-calc(3px);
    right: 0;
  }
}
.orm-customer-devider {
  background-color: #24282c1f;
  height: 1px;
  width: calc(100% + rem-calc(40px));
  margin: rem-calc(20px) 0 rem-calc(20px) rem-calc(-20px);
}
.orm-customer-icon--circle {
  border: 2px solid #24282c;
  border-radius: rem-calc(30px);
  width: rem-calc(30px);
  height: rem-calc(30px);
  padding: rem-calc(16px);
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-transform: uppercase;

  &.orm-customer-icon--circlewhite {
    border: 1px solid $white;
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: rem-calc(14px);
  }
}
.orm-customer-name {
  display: inline-block;
  font-size: rem-calc(14px);
  margin-left: rem-calc(8px);
  position: relative;
  top: rem-calc(-2px);
}
.orm-customer-links a {
  text-decoration: none;
  margin: rem-calc(16px) 0;
  display: block;
  &:hover,
  &:focus,
  .active {
    text-decoration: underline;
  }
}

.orm-login-error {
  padding-left: rem-calc(15px);
  top: -8px;
  position: relative;
  color: $orm-red;
}
